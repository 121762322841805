import { MouseEvent, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import {
  partBase,
  partActive,
  partHover,
  partFocus,
  partDisabled,
  partForceActive,
} from "./class-names";
import { Checkbox } from "../checkbox";
import { Icon, IconName } from "../icon";
import { Switch } from "../switch";

export type DropdownPartProps = {
  leftIcon?: IconName;
  rightIcon?: IconName;
  label?: string;
  labelOverride?: ReactNode;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: () => void;
  disabled?: boolean;
  active?: boolean;
  hidden?: boolean;
  toggled?: boolean;
  checked?: boolean;
  className?: string;
  indeterminate?: boolean;
};

export const DropdownPart = ({
  leftIcon,
  rightIcon,
  label,
  labelOverride,
  onClick,
  onMouseEnter,
  disabled,
  active,
  hidden,
  toggled,
  checked,
  className,
  indeterminate,
}: DropdownPartProps) => {
  const isToggleItem = toggled !== undefined;
  const isCheckboxItem = checked !== undefined;
  const isComponentItem = isCheckboxItem || isToggleItem;

  if (hidden) return null;

  return (
    <button
      className={twMerge(
        partBase,
        partActive,
        partHover,
        partFocus,
        disabled && partDisabled,
        active && partForceActive,
        className,
      )}
      onMouseEnter={onMouseEnter}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
      onMouseUp={(e) => (e.target as HTMLButtonElement).blur()}
      onTouchEnd={(e) => (e.target as HTMLButtonElement).blur()}
      type="button"
      aria-label={label}
    >
      <div className="flex gap-small items-center">
        {leftIcon && <Icon name={leftIcon} className="w-[1.6rem] h-[1.6rem]" />}
        {isToggleItem && (
          <Switch text={label} toggled={toggled} onClick={() => {}} />
        )}
        {isCheckboxItem && (
          <Checkbox
            label={label}
            checked={checked}
            indeterminate={indeterminate}
            onChange={() => {}}
          />
        )}
        {!isComponentItem && (
          <p className="text-ui text-left">{labelOverride || label}</p>
        )}
      </div>
      {rightIcon && <Icon name={rightIcon} />}
    </button>
  );
};
